// const
const MAPBOX_TOKEN =
  'pk.eyJ1IjoieWlob25nMDYxOCIsImEiOiJja2J3M28xbG4wYzl0MzJxZm0ya2Fua2p2In0.PNKfkeQwYuyGOTT_x9BJ4Q';
const MUNICIPALITY_CITIES_ARR = [
  '北京市',
  '上海市',
  '天津市',
  '重庆市',
  '香港特别行政区',
  '澳门特别行政区',
];

// styling: set to `true` if you want dash-line route
const USE_DASH_LINE = true;
// styling: route line opacity: [0, 1]
const LINE_OPACITY = 0.4;
// styling: map height
const MAP_HEIGHT = 600;

// IF you outside China please make sure IS_CHINESE = false
const IS_CHINESE = false;
const USE_ANIMATION_FOR_GRID = true;
const CHINESE_INFO_MESSAGE = (yearLength, year) => {
  const yearStr = year === 'Total' ? '所有' : ` ${year} `;
  return `我用 App 记录自己跑步 ${yearLength} 年了，下面列表展示的是${yearStr}的数据`;
}
const ENGLISH_INFO_MESSAGE = (yearLength, year) =>
  `Running Journey with ${yearLength} Years, the table shows year ${year} data`;

// not support English for now
const CHINESE_LOCATION_INFO_MESSAGE_FIRST =
  '我跑过了一些地方，希望随着时间推移，地图点亮的地方越来越多';
const CHINESE_LOCATION_INFO_MESSAGE_SECOND = '不要停下来，不要停下奔跑的脚步';
const INFO_MESSAGE = IS_CHINESE ? CHINESE_INFO_MESSAGE : ENGLISH_INFO_MESSAGE;

// marathon
const FULL_MARATHON_RUN_TITLE = IS_CHINESE ? '全程马拉松' : 'Full Marathon';
const HALF_MARATHON_RUN_TITLE = IS_CHINESE ? '半程马拉松' : 'Half Marathon';

// run
const MORNING_RUN_TITLE = IS_CHINESE ? '清晨跑步' : 'Morning Run';
const MIDDAY_RUN_TITLE = IS_CHINESE ? '午间跑步' : 'Midday Run';
const AFTERNOON_RUN_TITLE = IS_CHINESE ? '午后跑步' : 'Afternoon Run';
const EVENING_RUN_TITLE = IS_CHINESE ? '傍晚跑步' : 'Evening Run';
const NIGHT_RUN_TITLE = IS_CHINESE ? '夜晚跑步' : 'Night Run';

// walk
const MORNING_WALK_TITLE = IS_CHINESE ? '清晨散步' : 'Morning Walk';
const MIDDAY_WALK_TITLE = IS_CHINESE ? '午间散步' : 'Midday Walk';
const AFTERNOON_WALK_TITLE = IS_CHINESE ? '午后散步' : 'Afternoon Walk';
const EVENING_WALK_TITLE = IS_CHINESE ? '傍晚散步' : 'Evening Walk';
const NIGHT_WALK_TITLE = IS_CHINESE ? '夜晚散步' : 'Night Walk';


// other short-distance activity
const SWIM_TITLE = IS_CHINESE ? '游泳' : 'Swim';
const RIDE_TITLE = IS_CHINESE ? '骑行' : 'Ride';

// other long-distance activity
const HIKE_TITLE = IS_CHINESE ? '徒步' : 'Hike';
const ROAD_TRIP_TITLE = IS_CHINESE ? '自驾' : 'RoadTrip';


const RUN_TITLES = {
  FULL_MARATHON_RUN_TITLE,
  HALF_MARATHON_RUN_TITLE,

  MORNING_RUN_TITLE,
  MIDDAY_RUN_TITLE,
  AFTERNOON_RUN_TITLE,
  EVENING_RUN_TITLE,
  NIGHT_RUN_TITLE,

  MORNING_WALK_TITLE,
  MIDDAY_WALK_TITLE,
  AFTERNOON_WALK_TITLE,
  EVENING_WALK_TITLE,
  NIGHT_WALK_TITLE,

  SWIM_TITLE,
  RIDE_TITLE,
  HIKE_TITLE,
  ROAD_TRIP_TITLE
};

export {
  CHINESE_LOCATION_INFO_MESSAGE_FIRST,
  CHINESE_LOCATION_INFO_MESSAGE_SECOND,
  MAPBOX_TOKEN,
  MUNICIPALITY_CITIES_ARR,
  IS_CHINESE,
  INFO_MESSAGE,
  RUN_TITLES,
  USE_ANIMATION_FOR_GRID,
  USE_DASH_LINE,
  LINE_OPACITY,
  MAP_HEIGHT,
};

const nike = 'rgb(224,237,94)'; // if you want change the main color change here src/styles/variables.scss
const yellow = 'rgb(224,237,94)';
const green = 'rgb(0,237,94)';
const pink = 'rgb(237,85,219)';
const cyan = 'rgb(112,243,255)';
const IKB = 'rgb(0,47,167)';
const wpink = 'rgb(228,212,220)';
const gold = 'rgb(242,190,69)';
const purple = 'rgb(154,118,252)';
const veryPeri = 'rgb(105,106,173)';//长春花蓝
const red = 'rgb(255,0,0)';//大红色


// If your map has an offset please change this line
// issues #92 and #198
export const NEED_FIX_MAP = false;
export const MAIN_COLOR = nike;

export const RUN_COLOR = yellow;
export const WALK_COLOR = cyan;

export const SWIM_COLOR = gold;
export const HIKE_COLOR = pink;

export const RIDE_COLOR = green;
export const ROAD_TRIP_COLOR = purple;


export const COUNTRY_FILL_COLOR = wpink;
export const PROVINCE_FILL_COLOR = '#47b8e0';
